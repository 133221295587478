import styled from 'styled-components';
import { desktopHover, pxtorem, pxtovw } from '../../utils/Tools';

export const StyledTextBanner = styled.section`
  padding: ${pxtorem(200)} 0};
  text-align: center;

  @media (max-width: 768px) {
    padding: 100px 0;
  }

  .text {
    font: 300 max(8.333vw, 56px)/90% var(--primaryFont);
    @media (min-width: 1920px) {
      font: 300 160px/90% var(--primaryFont);
    }

    text-transform: uppercase;

    p {
      margin: 0;
    }

    a {
      color: var(--aqua);
      transition: color .25s;
      position: relative;

      &:before {
        content: '';
        transition: transform 1s cubic-bezier(.22, 1, .36, 1);
        position: absolute;
        bottom: ${pxtovw(30)};
        border-top: 1px solid currentColor;
        width: 100%;
        transform-origin: right center;
        transform: scaleX(0);
        /* background: var(--color); */
      }
      
      ${desktopHover(`
        color: var(--pebble);
        &:before {
          transform-origin: left center;
          transform: scaleX(1);
        }
      `)}
    }
  }
`;