import React, { useEffect, useRef, useState } from 'react';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { ServicesPageData } from '../../pages/services';
import { Wrapper } from '../../styles/Wrapper.style';
import {
  CaseStudyItem,
  CaseStudyItemData,
} from '../CaseStudyItem/CaseStudyItem';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledServicesProcess } from './ServicesProcess.style';

export const ServicesProcess: React.FC<ServicesProcessProps> = ({
  data,
  caseStudies,
}) => {
  const isMobile = useMatchMedia('(max-width: 768px)');
  const isSmallLaptop = useMatchMedia('(max-width: 1024px)');
  const { process, caseStudiesLabel, phasesLabel } = data; 

  return (
    <StyledServicesProcess>
      <Wrapper width={1664}>
        {process.map(
          ({ description, phases, title, featuredCaseStudies }, index) => {
            const firstlistColumn = phases.slice(0, 3);
            const secondlistColumn = phases.slice(3, 6);

            const lowerCaseCaseStudies = featuredCaseStudies.map((c) =>
              c.caseStudy?.toLowerCase()
            );
            const relevant = caseStudies.filter((c) =>
              lowerCaseCaseStudies.includes(c.title?.toLowerCase())
            );
            const hasOneCaseStudy = relevant.length === 1;
            return (
              <React.Fragment key={title}>
                <div className="process-grid-title">
                  {!isMobile && (
                    <ShowOnScroll>
                      <p className="step">
                        ({(index + 1).toString().padStart(2, '0')})
                      </p>
                    </ShowOnScroll>
                  )}
                  <ShowOnScroll>
                    <h3 className="process-title">
                      <span>({(index + 1).toString().padStart(2, '0')})</span>
                      {title}
                    </h3>
                  </ShowOnScroll>
                  <div className="grid-space" />
                  <ShowOnScroll delay={150} className="process-details">
                    <p>{description}</p>
                    <p className="label">{phasesLabel}</p>
                    <div className="process-phases">
                      <ul>
                        {firstlistColumn.map(({ service }) => (
                          <li key={service}>{service}</li>
                        ))}
                      </ul>
                      <ul>
                        {secondlistColumn.map(({ service }) => (
                          <li key={service}>{service}</li>
                        ))}
                      </ul>
                    </div>
                  </ShowOnScroll>
                </div>
                <ShowOnScroll>
                  <div className="process-case-studies">
                    {!isSmallLaptop && <div></div>}
                    <p className="cs-label">{caseStudiesLabel}</p>
                    <div></div>
                  </div>
                  <div
                    className={`case-study-cards ${
                      relevant.length === 1
                        ? 'case-studies-fw'
                        : 'case-studies-split'
                    }`}
                  >
                    {!isSmallLaptop && <div></div>}
                    {relevant.map((caseStudy, i) => (
                      <ShowOnScroll delay={i * 100}>
                        <CaseStudyItem
                          item={caseStudy}
                          className={
                            hasOneCaseStudy ? 'cs-single' : 'cs-double'
                          }
                          ratio="custom"
                          customRatio={hasOneCaseStudy ? '1283/480' : '589/480'}
                        />
                      </ShowOnScroll>
                    ))}
                  </div>
                </ShowOnScroll>
              </React.Fragment>
            );
          }
        )}
      </Wrapper>
    </StyledServicesProcess>
  );
};

interface ServicesProcessProps {
  data: { process: ServicesPageData['process'] } & {
    phasesLabel: string;
    caseStudiesLabel: string;
    caseStudiesCTA: string;
  };
  caseStudies: CaseStudyItemData[];
}
