import React, { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';

import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledTextBanner } from './TextBanner.style';

export function TextBanner({ data }: PropsWithChildren<TextBannerProps>): JSX.Element {
  const { text } = data[0] || {};

  return (
    <StyledTextBanner>
      <Wrapper width={1700}>
        <ShowOnScroll>
          <ReactMarkdown className="text">
            {text}
          </ReactMarkdown>
        </ShowOnScroll>
      </Wrapper>
    </StyledTextBanner>
  )
}

interface TextBannerProps {
  data: TextBannerData[];
}

export interface TextBannerData {
  text: string;
}