import { Link } from 'gatsby';
import React, { useEffect, useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { paths } from '../../utils/paths';
import { PactContext } from '../PactContext';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledCaseStudyItem } from './CaseStudyItem.style';

export const CaseStudyItem: React.FC<CaseStudyItemProp> = ({ item, ratio = 'square', customRatio, className }) => {
  //#region Hooks / Lifecycles
  const { language } = useContext(PactContext);

  const linkLabel = useRef<Record<string, string>>({
    en: 'View Case Study',
    fr: 'Lire l\'étude de cas'
  });

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { client, featuredImage, smallDescription, slug } = item;
  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledCaseStudyItem ratio={ratio} customRatio={customRatio} className={className}>
      {featuredImage && (
        <ShowOnScroll>
          <div className="media">
            <Link to={`${paths[language].prefix === '' ? '' : `/${paths[language].prefix}`}/${paths[language].caseStudies}/${slug}`}>
              <PactImage image={featuredImage} alt="" />
            </Link>
          </div>
        </ShowOnScroll>
      )}

      <ShowOnScroll>
        <div className="info">
          <span className="client">{client}</span>
          <Link className="link" to={`${paths[language].prefix === '' ? '' : `/${paths[language].prefix}`}/${paths[language].caseStudies}/${slug}`}>{linkLabel.current[language]}</Link>
          <div className="desc">
            <ReactMarkdown>{smallDescription}</ReactMarkdown>
          </div>
        </div>
      </ShowOnScroll>
    </StyledCaseStudyItem>
  )
  //#endregion
}

interface CaseStudyItemProp {
  item: CaseStudyItemData;
  ratio?: 'wide' | 'tall' | 'square' | 'custom';
  customRatio?: string;
  className?: string;
}

export interface CaseStudyItemData {
  title?: string;
  client: string;
  weight: number;
  showInListing: boolean;
  category?: string;
  featuredImage: any;
  smallDescription: string;
  slug: string;
}