import styled from 'styled-components';
import { desktopHover, keepRatio, pxtorem } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';

const ratioMap: Record<string, string> = {
  'wide': '1358/905',
  'tall': '826/1024',
  'square': '1/1',
}

export const StyledCaseStudyItem = styled.div<StyledCaseStudyItemProp>`
  .media {
    ${p => p.ratio === 'custom' ? keepRatio(p.customRatio) : keepRatio(ratioMap[p.ratio])};

    ${StyledPactImage}, a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    a {
      transition: opacity 1s;
      ${desktopHover('opacity: 0.7;')}
    }

    ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }

    ${StyledPactImage} {
      overflow: hidden;
    }

    .gatsby-image-wrapper {
      transition: transform 2s cubic-bezier(.22, 1, .36, 1);
    }

    ${desktopHover(`
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    `)}
  }

  .info {
    margin-top: 40px;
  }

  .client, .link {
    display: block;
    font: 400 16px/19px var(--secondaryFont);
    text-transform: uppercase;
  }

  .link {
    margin-top: 10px;
    transition: opacity .25s;
    opacity: 0.5;

    ${desktopHover('opacity: 1')};
  }

  .desc {
    font: 300 ${pxtorem(30)}/${pxtorem(42)} var(--primaryFont);
    max-width: 750px;
    margin-top: 20px;
    p {
      margin: 0;  
    }
  }
`

interface StyledCaseStudyItemProp {
  ratio: 'wide' | 'tall' | 'square' | 'custom';
  customRatio?: string;
}