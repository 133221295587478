import { useEffect, useRef, useState } from "react"

export const useMatchMedia = (query: string) => {
  const matchRef = useRef(typeof window === 'undefined' ? null : window.matchMedia(query));
  const [matches, setMatches] = useState(!!matchRef.current?.matches || false);

  useEffect(() => {
      let mm = matchRef.current;
      mm?.addEventListener('change', onMatchMediaChange);
  
      return () => {
        mm.removeEventListener('change', onMatchMediaChange);
      };
  }, []);

  const onMatchMediaChange = (e: MediaQueryListEvent): void => {
    setMatches(e.matches);
  };

  return matches;
}