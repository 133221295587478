import styled from 'styled-components';
import { pxtorem, pxtovw } from '../../utils/Tools';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledServicesProcess = styled.div`
  width: 100%;
  --border: 1px solid #686868;
  --x-pad: ${pxtorem(50)};
  --y-pad: ${pxtorem(120)};

  --left-col: min(20%, 381px);
  --grid-cols: var(--left-col) 1fr 1fr;

  p,
  h3 {
    margin: 0;
  }

  .process-grid-title {
    border-top: var(--border);
    padding-inline: var(--x-pad);
    padding-top: var(--y-pad);
    display: grid;
    grid-template-columns: var(--grid-cols);

    .grid-space {
      display: none;
    }

    @media (max-width: 1450px) {
      grid-template-columns: var(--left-col) 1fr;

      .grid-space {
        display: block;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;

      .grid-space {
        display: none;
      }
    }
  }

  .process-title {
    font: 300 max(${pxtovw(80)}, 32px) / 90% var(--primaryFont);
    text-transform: uppercase;
    margin-right: 0;
    max-width: 9ch;

    span {
      display: none;
      margin-right: ${pxtorem(16)};
    }

    @media (min-width: 1920px) {
      font: 300 80px/90% var(--primaryFont);
    }

    @media (max-width: 768px) {
      max-width: 100%;
      span {
        display: inline;
      }
    }
  }

  .step {
    margin: 0;
    font: 300 max(6.25vw, 44px) / 90% var(--primaryFont);
    @media (min-width: 1920px) {
      font: 300 120px / 90% var(--primaryFont);
    }
  }

  .process-details {
    p,
    ul {
      max-width: 600px;
    }
    
    p:not(.label) {
      margin-bottom: ${pxtorem(80)};
    }

    p,
    ul {
      font: 400 ${pxtorem(20)} / 150% var(--secondaryFont);
    }

    .process-phases {
      display: flex;
      gap: ${pxtorem(16)};
    }

    ul {
      width: min(266px, 90%);
      list-style: none;
      li {
        margin-bottom: ${pxtorem(16)};
      }
    }

    .label {
      margin-bottom: ${pxtorem(40)};
    }

    @media (max-width: 1450px) {
      margin-top: ${pxtorem(80)};
    }

    @media (max-width: 1024px) {
      margin-top: ${pxtorem(48)};
    }
  }

  .label {
    font: 300 ${pxtorem(16)} / 120% var(--secondaryFont);
    text-transform: uppercase;
  }

  .process-case-studies {
    margin-top: ${pxtorem(80)};
    width: 100%;
  
    display: grid;
    grid-template-columns: var(--grid-cols);

    padding-inline: var(--x-pad);
    margin-bottom: ${pxtorem(40)};

    p {
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      margin-bottom: ${pxtorem(24)};
    }
  } 

  .cs-single,
  .cs-double {
    width: 100%;
    .link {
      display: block;
    }
  }

  .cs-single {
    max-width: 100%;
  }

  .cs-double {
    max-width: 589px;
  }

  .case-study-cards {
    display: grid;  
    padding-bottom: var(--y-pad);
    padding-inline: var(--x-pad);
    border-bottom: var(--border);
  }

  .case-studies-fw {
    grid-template-columns: var(--left-col) 1fr;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .case-studies-split {
    grid-template-columns: var(--grid-cols);

    ${StyledShowOnScroll} {
      width: 100%;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: ${pxtorem(24)};
    }
  }

  

  .cs-label {
    font: 400 ${pxtorem(16)} / 120% var(--secondaryFont);
    text-transform: uppercase;
    color: var(--pebble);
   
  }

  @media (max-width: 568px) {
    --x-pad: 16px;
  }
`;
