import { graphql } from "gatsby";
import React, { useMemo } from "react";
import { CaseStudyItemData } from "../components/CaseStudyItem/CaseStudyItem";
import { HeroBannerData } from "../components/HeroBanner/HeroBanner";
import { Page } from "../components/Page";
import { SEO, SEOProps } from "../components/SEO/SEO";
import { ServicesPage } from "../components/ServicesPage/ServicesPage";
import { TextBannerData } from "../components/TextBanner/TextBanner";
import { paths } from "../utils/paths";
import { withLang } from "../utils/reactTools";
import { getLocaleMdData } from "../utils/Tools";
import { CaseStudiesProps } from "./case-studies";

export const Services: React.FC<ServicesProps> = ({ data, language, ...props }) => {
  const _data = getLocaleMdData(data); 


  const allCaseStudies = useMemo<CaseStudyItemData[]>(() => {
    const allPublicCaseStudies = (data.allCaseStudies.nodes || []).filter(x => x.fields.locales[language].showInListing);
    const filteredByLang = allPublicCaseStudies.map(n => n.fields.locales[language]);
    return filteredByLang.sort((a, b) => {
      const aWeight = a.weight || 0;
      const bWeight = b.weight || 0;

      if (aWeight > bWeight) {
        return -1;
      } else if (aWeight < bWeight) {
        return 1;
      }

      return 0;
    })
  }, []);
  
  let showMissingFrench: boolean = false;
  if (
    language === "fr" &&
    _data["fr"].heroBanner[0].text === _data["en"].heroBanner[0].text
    ) {
      showMissingFrench = true;
    }
  const { pageTheme, seo } = _data[language] || {};

  const globalSEO: SEOProps =
    (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title, description, ogImage } = (seo ? seo[0] : null) || {};
  return (
    <Page
      background={pageTheme}
      showMissingFrenchBanner={showMissingFrench}
      language={language}
      slugs={{
        en: `${paths.en.prefix}/team`,
        fr: `/${paths.fr.prefix}/equipe`,
      }}
    >
      <SEO
        title={title ? title : globalSEO.title}
        description={description ? description : globalSEO.description}
        ogImage={ogImage ? ogImage : globalSEO.ogImage}
      />
      <ServicesPage data={_data[language]} caseStudies={allCaseStudies} />
    </Page>
  );
  //#endregion
};

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
        }
      }
    }

    markdownRemark(fileAbsolutePath: { regex: "/pages/services.md/" }) {
      fields {
        locales {
          en {
            pageTheme
            ...SeoFragmentEN
            ...heroBannerFragmentEN
            phasesLabel
          	caseStudiesLabel
            caseStudiesCTA
            process {
							title
              description
              phases {
              	service
              }
              featuredCaseStudies {
								caseStudy
              }
            }
            ...textBannerFragmentEN
          }
          fr {
            heroBanner {
              text
            }
          }
        }
      }
    }

    allCaseStudies: allMarkdownRemark(filter: {frontmatter: {type: {eq: "case-study"}}}) {
      nodes {
        fields {
          locales {
            en {
              title
              client
              slug
              showInListing
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 2716
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    transformOptions: {fit: COVER}
                  )
                } 
              }
            }
          }
        }
      }
    }


  }
`;

export interface ServicesPageData {
  pageTheme: ThemeBG;
  seo: SEOProps[]; 
  heroBanner: HeroBannerData[];
  textBanner: TextBannerData[];
  process: ServiceProcessData[];
  phasesLabel: string;
  caseStudiesLabel: string;
  caseStudiesCTA: string;
  allCaseStudies: CaseStudiesProps['data'];
}

interface ServiceProcessData {
  title: string;
  description: string;
  phases: {
    service: string;
  }[];
  featuredCaseStudies: {
    caseStudy: string;
  }[]
}


export type ServicesProps = LocalizedAllMarkdownDataRelation<
  ServicesPageData,
  CaseStudyItemData
> &
  PropsWithLang<ServicesPageData>;

  

export default withLang(Services, "en");
