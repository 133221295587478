import React, { useMemo } from 'react';
import { ServicesPageData } from '../../pages/services';
import { CaseStudyItemData } from '../CaseStudyItem/CaseStudyItem';
import { HeroBanner } from '../HeroBanner/HeroBanner';
import { ServicesProcess } from '../ServicesProcess/ServicesProcess';
import { TextBanner } from '../TextBanner/TextBanner';
import { StyledServicesPage } from './ServicesPage.style';

export const ServicesPage: React.FC<ServicesPageProps> = ({ data, caseStudies }) => {
  const { heroBanner, textBanner, process, caseStudiesCTA, caseStudiesLabel, phasesLabel } = data;

  return (
    <StyledServicesPage>
      <HeroBanner data={heroBanner[0]} />
      <ServicesProcess data={{process, caseStudiesCTA, caseStudiesLabel, phasesLabel}} caseStudies={caseStudies} />
      <TextBanner data={textBanner} />
    </StyledServicesPage>
  );
};

export interface ServicesPageProps {
  data: ServicesPageData & {
    phasesLabel: string;
    caseStudiesLabel: string;
    caseStudiesCTA: string;
  };
  caseStudies: CaseStudyItemData[];
}
