import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';
import { StyledHeroBanner } from '../HeroBanner/HeroBanner.style';

export const StyledServicesPage = styled.div`
  ${StyledHeroBanner} {
    .subtitle {
      max-width: 749px;
      margin-inline: auto;
      font: 400 ${pxtorem(20)} / 150% var(--secondaryFont);
      text-transform: none;

      @media (min-width: 1920px) {
        margin-top: ${pxtorem(80)};
      }
    }
  }

  
`;